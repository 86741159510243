.thebox {
    transition: all 1s;
    background-color: coral;
    height: 40px;
    width: 40px;
    position: absolute;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    
}

.themiddle {
    transition: all 0.5s;
    top: 45%;
    bottom: 45%;
    left: 45%;
    right: 45%;
}

.thetop {
    transition: all 0.5s;
    top: 0%;
    bottom: 90%;

}

.theleft {
    transition: all 0.5s;
    right: 90%;
    left: 0%;
}

.theright {
    transition: all 0.5s;
    right: 0%;
    left: 86.5%;
}

.thebottom {
    transition: all 0.5s;
    top: 86.5%;
    bottom: 0%;
}

.thewall{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}

.walltouch{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    font-size: 22px;    
}

.goldspan{
    background-color: gold;
    color: black;
    font-weight: 500;
    margin-right: 3px;
    padding: 1px;
}

.silverspan{
    background-color: silver;
    color: black;
    font-weight: 500;
    margin-right: 3px;
    padding: 1px;
}

.bronzespan{
    background-color: brown;
    color: black;
    font-weight: 500;
    padding: 1px;
}

.complet0 {
    background-color: darkgreen;
}

.complet1 {
    background-color: darkblue;
}

.complet2 {
    background-color: darkred;
}